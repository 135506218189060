<template>
  <div>
    <v-img
      id="coverImage"
      src="https://storage.googleapis.com/gkbi-1.appspot.com/WhatsApp%20Image%202021-03-14%20at%2022.14.47.jpeg"
      :height="isOnPC ? '90vh': '30vh'"
    >
      <div v-if="isOnPC" style="height: 100%" class="d-flex" :class="{
        'align-center': isOnPC,
        'align-end': !isOnPC,
        'justify-end': isOnPC
      }">
        <v-sheet
          id="mainCard"
          class="pa-4"
          :style="isOnPC ?
            ({
              position: 'relative',
              right: '50px',
              width: '500px'
            }) : ({
            })"
          >
          <h1 class="text-h5 font-weight-bold">Pengadaan dan Pembenahan Sound System &amp; Alat Musik</h1>
          <p class="text--secondary">
            Pemuda &amp; Remaja Gereja Kristen Bahtera Injil (GKBI)<br />
            Jemaat Ekklesia Kristus Karombasan
          </p>
          <v-progress-linear color="accent" value="0" height="20px">0%</v-progress-linear>
          <p><span class="font-weight-bold text-h5">Rp 0</span> dari sasaran Rp 20.000.000</p>
          <div class="d-inline-block">
            <span class="font-weight-bold text-h5">0</span>
            penyumbang
          </div>
        </v-sheet>
      </div>
    </v-img>
    <v-container>
      <v-sheet class="mb-4" v-if="!isOnPC">
        <h1 class="text-h5 font-weight-bold">Pengadaan dan Pembenahan Sound System &amp; Alat Musik</h1>
        <p class="text--secondary">
          Pemuda &amp; Remaja Gereja Kristen Bahtera Injil (GKBI)<br />
          Jemaat Ekklesia Kristus Karombasan
        </p>
        <v-progress-linear color="accent" value="0" height="20px">0%</v-progress-linear>
        <p><span class="font-weight-bold text-h5">Rp 0</span> dari sasaran Rp 20.000.000</p>
        <div class="d-inline-block">
          <span class="font-weight-bold text-h5">0</span>
          penyumbang
        </div>
      </v-sheet>
      <hr class="my-6" />
      <div>
        <p class="text-body-1 font-weight-bold">
          Salam dalam kasih Yesus Kristus.
        </p>
        <p>
          Dalam rangka memajukan pelayanan gereja, maka kami Pemuda
          dan Remaja Gereja Kristen Bahtera Injil (GKBI) jemaat Ekklesia
          Kristus Karombasan akan mengadakan Pengadaan dan Pembenahan Sound
          System dan Alat Musik.
        </p>
        <p>
          Untuk maksud tersebut, tentunya kami memerlukan dana/biaya. Untuk itu,
          kami Pemuda dan Remaja GKBI Ekklesia Kristus Karombasan dengan
          penuh kerendahan hati memohon bantuan dari Bapak/Ibu/Saudara/-i agar
          kiranya dapat membantu dan menopang kegiatan ini.
        </p>
        <p>
          Atas bantuan dari Bapak/Ibu/Saudara/-i kami ucapkan terima kasih.
          Tuhan Yesus memberkati.
        </p>
        <p>
          Untuk melihat proposal dan rincian dana, silahkan klik tautan berikut:
          <a href="/test.html">Proposal Pengadaan dan Pembenahan Alat Musik GKBI Ekklesia Kristus Karombasan</a>
        </p>
      </div>
      <h5 class="text-h5">Cara Berdonasi</h5>
      <p>Untuk berdonasi, Anda bisa....</p>
      <h5 class="text-h5">Daftar Sumbangan</h5>
      <p>Berikut adalah daftar donasi yang sudah kami terima.</p>
      <v-simple-table>
      </v-simple-table>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  },

  computed: {
    isOnPC () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  }
}
</script>

<style scoped>
#coverImage {
  position: relative;
  width: 100%;
}
</style>
